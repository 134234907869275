<template>
    <div>
        <a-alert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- filter -->
        <ARow :gutter="[8, 8]">
            <ACol :xl="6" :md="8" :sm="12" :span="24" v-can:hide.distributor>
                <FilterRegional
                    style="width:100%;"
                    v-model:value="state.params.region"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterProvince
                    style="width:100%;"
                    v-model:value="state.params.provinsi"
                    v-model:region="state.params.region"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24" v-can:hide.distributor>
                <FilterArea
                    style="width:100%;"
                    v-model:value="state.params.area"
                    v-model:provinsi="state.params.provinsi"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24" v-can:hide.distributor>
                <FilterKabupatenKota
                    style="width:100%;"
                    v-model:value="state.params.kabupaten"
                    v-model:region="state.params.region"
                    v-model:provinsi="state.params.provinsi"
                    v-model:area="state.params.area"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24" v-can:hide.distributor>
                <FilterDistributor
                    style="width:100%;"
                    v-model:value="state.params.distributor"
                    v-model:distributor="state.params.distributor"
                    v-model:region="state.params.region"
                    v-model:area="state.params.area"
                    v-model:provinsi="state.params.provinsi"
                    v-model:kabupaten="state.params.kabupaten"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterToko
                    style="width:100%;"
                    v-model:value="state.params.toko"/>
            </ACol>
            <ACol
                v-if="hasRoles([ROLE_ADMIN_BK, ROLE_HELPDESK, ROLE_TSO, ROLE_ASM, ROLE_SPC_GROUP])"
                :xl="6" :md="8" :sm="12" :span="24">
                <AMonthPicker
                    style="width:100%;"
                    v-model:value="state.params.periode"
                    placeholder="Pilih Periode"
                    format="MMMM YYYY"/>
            </ACol>
            <ACol
                v-if="hasRoles([ROLE_ADMIN_BK, ROLE_HELPDESK, ROLE_ASM, ROLE_SPC_GROUP])"
                :xl="6" :md="8" :sm="12" :span="24">
                <ASelect
                    style="width:100%;"
                    v-model:value="state.params.status"
                    show-search
                    allow-clear
                    placeholder="Pilih Status"
                    :options="state.statusLists"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <AButton
                    type="primary"
                    title="cari"
                    @click="fetchDataList"
                    :loading="state.isFetching">
                    <span
                        v-if="!state.isFetching"
                        class="fa fa-search"
                        aria-hidden="true"/>
                    <span v-else>
                        Memuat Data ...
                    </span>
                </AButton>
            </ACol>
        </ARow>

        <div class="row justify-content-end mt-4">
            <div class="col-lg-6 col-md-12"></div>
            <div class="col-lg-6 col-md-12 text-right">
                <ASpace>
                    <ATooltip title="Download Excel">
                        <DownloadExcel
                            :url="state.endpoint.current"
                            :params="queryParams()"
                            @errors="errorMessage"/>
                    </ATooltip>
                </ASpace>
            </div>
        </div>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <MdTable
                :columns="state.columns"
                :data-source="state.data"
                size="small"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                :pagination="{
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                    showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                    total: state.meta.total,
                    pageSize: state.meta.per_page,
                    current: state.meta.page,
                }"
                @change="handleTableChange"
                :loading="state.isFetching">

                <template #no="{ index }">
                    <span>
                        {{ (state.meta.page - 1) * state.meta.per_page + 1 + index }}
                    </span>
                </template>
                <template #action="{ record }">
                    <span>
                        <ATooltip title="Detail History">
                            <button
                                type="button"
                                @click="btnDetail(record.details)"
                                class="btn btn-sm btn-success"
                                shape="round"
                                size="small">
                                <i class="fe fe-info fa-lg" />&nbsp;Detail
                            </button>
                        </ATooltip>
                    </span>
                </template>
            </MdTable>
        </div>

        <!-- details by id -->
        <DetailHistory
            v-if="visibleDetailModal"
            v-model:visible="visibleDetailModal"
            v-model:items="visibleDetailItemsModal"/>
    </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted, watch } from 'vue'
import apiClient from '@/services/axios'
import { Modal, message } from 'ant-design-vue'
import FilterProvince from '@/components/filter/FilterProvince'
import FilterKabupatenKota from '@/components/filter/FilterKabupatenKota'
import FilterRegional from '@/components/filter/FilterRegional'
import FilterArea from '@/components/filter/FilterArea'
import FilterToko from '@/components/filter/FilterToko'
import FilterDistributor from '@/components/filter/FilterDistributor'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import DetailHistory from './DetailHistory'
import {
    updateUrlWithQuery,
    extractQueryParams,
} from '@/helpers/globalmixin'
import {
    ROLE_ADMIN_BK,
    ROLE_ASM,
    ROLE_TSO,
    ROLE_SPC_GROUP,
    ROLE_HELPDESK,
    hasRoles,
} from '@/helpers'
import moment from 'moment'

export default defineComponent({
    components: {
        FilterProvince,
        FilterKabupatenKota,
        FilterRegional,
        FilterArea,
        FilterToko,
        FilterDistributor,
        DownloadExcel,
        DetailHistory,
    },
    setup() {
        const errorMessage = ref()
        const state = reactive({
            columns: [
                {
                    title: 'NO',
                    slots: { customRender: 'no' },
                },
                {
                    title: 'Periode',
                    dataIndex: 'periode',
                },
                {
                    title: 'Region',
                    dataIndex: 'regional',
                },
                {
                    title: 'ID Toko',
                    dataIndex: 'customer_code',
                },
                {
                    title: 'Nama Toko',
                    dataIndex: 'customer_name',
                },
                {
                    title: 'Alamat',
                    dataIndex: 'address',
                },
                {
                    title: 'Area',
                    dataIndex: 'area',
                },
                {
                    title: 'Kota',
                    dataIndex: 'city',
                },
                {
                    title: 'Provinsi',
                    dataIndex: 'province',
                },
                {
                    title: 'Mapping Distributor',
                    dataIndex: 'distributor_mapping',
                },
                {
                    title: 'SSM',
                    dataIndex: 'user_ssm',
                },
                {
                    title: 'ASM',
                    dataIndex: 'user_sm',
                },
                {
                    title: 'TSO',
                    dataIndex: 'user_am',
                },
                {
                    title: 'Status',
                    dataIndex: 'status_approval',
                },
                {
                    title: 'Action',
                    slots: { customRender: 'action' },
                },
            ],
            endpoint: {
                current: '/api/report-lelang/history-approval',
            },
            data: [],
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            isFetching: false,
            isDownloading: false,
            params: extractQueryParams({
                q: '',
                region: [],
                provinsi: [],
                area: [],
                kabupaten: [],
                toko: [],
                status: null,
                periode: moment(new Date()).startOf('month').format('YYYY-MM'),
                distributor: [],
                page: 1,
                "per-page": 10,
            }),
            statusLists: [
                {
                    label: "Approve level 1 TSO",
                    value: 2,
                },
                {
                    label: "Reject level 1 TSO",
                    value: 3,
                },
                {
                    label: "Approve level 2 ASM",
                    value: 4,
                },
                {
                    label: "Reject level 2 ASM",
                    value: 5,
                },
                {
                    label: "Delete TSO",
                    value: 6,
                },
                {
                    label: "Delete - Approve ASM",
                    value: 7,
                },
                {
                    label: "Delete - Reject ASM",
                    value: 8,
                },
                {
                    label: "Delete - Approve SPC",
                    value: 9,
                },
                {
                    label: "Delete - Reject SPC",
                    value: 10,
                },
            ],
        })

        const handleTableChange = (page, filters, sorter) => {
            state.params.page = page.current
            state.params["per-page"] = page.pageSize
            
            fetchDataList()
        }

        const queryParams = () => {
            let params = state.params

            if (state.params.periode) {
                params.periode = moment(state.params.periode).format('YYYY-MM')
            }

            return updateUrlWithQuery(params)
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true
            
            apiClient
                .get(state.endpoint.current, {
                    params: queryParams(),
                })
                .then(({ data }) => {
                    const { items, _meta } = data
                    
                    items.forEach((item, i) => {
                        item.key = i
                        item['progress_download'] = false
                    })

                    state.data = items
                    state.meta.page = _meta.currentPage
                    state.meta.per_page = _meta.perPage
                    state.meta.total = _meta.totalCount
                })
                .finally(() => {
                    state.isFetching = false
                })
        }

        // handle detail by item
        const visibleDetailModal = ref(false);
        const visibleDetailItemsModal = ref(null);

        const btnDetail = (items) => {
            visibleDetailModal.value = true
            visibleDetailItemsModal.value = items
        }

        // handle vue
        onMounted(() => {
            fetchDataList()

            // validation list status for a ASM role
            if (hasRoles([ROLE_ASM])) {
                state.statusLists = state.statusLists.filter(item => {
                    if (item.value == 2 || item.value == 3) {
                        return item
                    }
                })
            }
        })

        return {
            fetchDataList,
            state,
            // rowSelection,
            handleTableChange,
            errorMessage,
            btnDetail,
            visibleDetailModal,
            visibleDetailItemsModal,
            queryParams,
            hasRoles,
            ROLE_ADMIN_BK,
            ROLE_HELPDESK,
            ROLE_ASM,
            ROLE_TSO,
            ROLE_SPC_GROUP,
        }
    },
})
</script>
